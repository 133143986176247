import type { Affise, IPromoCode, IReferralCampaignReportParams, IReferralCode } from '@dev-fast/types';

export class GetCampaigns {
  static readonly type = '[Referrals] Get Campaigns';
}

export class GetInfo {
  static readonly type = '[Referrals] Get Info';
}

export class GetCampaignReferralsReport {
  static readonly type = '[Referrals] Get Campaign Referrals Report';

  constructor(public params: IReferralCampaignReportParams) {}
}

export class SetActiveCampaign {
  static readonly type = '[Referrals] Set Active Campaign';

  constructor(public id: number) {}
}

export class CreateCampaign {
  static readonly type = '[Referrals] Create Compaign';

  constructor(public payload: Partial<IReferralCode>) {}
}

export class ChangeCampaign {
  static readonly type = '[Referrals] Change Compaign';

  constructor(public payload: Partial<IReferralCode>) {}
}

export class GetCurrentPromo {
  static readonly type = '[Referrals] Get Current Promo';
}

export class RemoveActivationErr {
  static readonly type = '[Referrals] Remove Activation Err';
}

export class AffiseEvent {
  static readonly type = '[Referrals] Affise Event';

  constructor(public affise: Affise) {}
}

export class OpenPromoModal {
  static readonly type = '[Referrals] Open Promo Modal';

  constructor(public code: IPromoCode) {}
}

export class ActivatePromoCode {
  static readonly type = '[Referrals] Activate Promo Code';

  constructor(public code: string) {}
}

export class ActivateRefCode {
  static readonly type = '[Referrals] Activate Ref Code';

  constructor(public code: string) {}
}

export class ClickRefCode {
  static readonly type = '[Referrals] Click Ref Code';

  constructor(public code: string) {}
}

export class ClickPromoCode {
  static readonly type = '[Referrals] Click Promo Code';

  constructor(public code: string) {}
}
